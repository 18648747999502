$(document).ready(function() {

    //slider strats here qd
    $('.formobilesli').click(function() {
        UIkit.lightbox('.mob-sli').show(0);
    });

    $(".mob-sli a").each(function(index) {
        var matched = $(".mob-sli a");
        var matchedLength = matched.length;
        //console.log( index + 1);
        $(this).attr('data-caption', index + 1 + ' / ' + matchedLength);
    });
    //slider end here qd


    jQuery('video').on("loadeddata", function() {
        jQuery('video').attr('controlsList', 'nodownload');
        jQuery('video').bind('contextmenu', function() {
            return false;
        });
        jQuery('video').attr('disablePictureInPicture', 'true');
    });

    //video play slider
    $(".playVideo").on("click", function() {
        $(this).parents(".video-poster").hide();
        $(this).parents(".video-poster").hide();
        $(this).parents(".video-poster").siblings(".video-stream").show();
        $(this).parents(".video-poster").siblings(".video-stream").children("video").get(0).play();
        $(".scroll-down").hide();
    });

    //header search click
    $(".openSearch").on("click", function() {
        $(".hide-search").slideToggle("slow");
        $(this).children("i").toggleClass("fa-close");
        $(".hide-city").hide();
        $(".hide-menu").hide();
        //$(".openMenu").removeClass("bg-gray");
        $('html').addClass("overflow-hidden");
        $(".openMenu").children("i").removeClass("fa-close").addClass("fa-bars");
    });

    //Login Popup click
    $(".openLogin").on("click", function() {
        $(".login-popup").fadeIn("slow");
        $(".signup-popup").hide();
        $(this).addClass("active");
        $(".openSignup").removeClass("active");
    })

    //header search click
    $(".openSignup").on("click", function() {
        $(".signup-popup").fadeIn("slow");
        $(".login-popup").hide();
        $(this).addClass("active");
        $(".openLogin").removeClass("active");
    })

    //Header scroll fix
    // Hide Header on on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('.header-wrp').outerHeight();

    $(window).scroll(function(event) {
        didScroll = true;
    });

    //video hover
    var figure = $(".video").hover(hoverVideo, hideVideo);

    function hoverVideo(e) {
        $('video', this).get(0).play();
    }

    function hideVideo(e) {
        $('video', this).get(0).pause();
    }

    //
    $(".openFilterClick").on("click", function() {
        $(".filteropen").fadeIn("fast", function() {
            $(".filter-card-wrp-inner").addClass("active");
            $(".filter-card-wrp-inner").removeClass("inactive")
        });

    })

    $(".closeFilter").on("click", function() {
        $(".filteropen").fadeOut("fast", function() {
            $(".filter-card-wrp-inner").removeClass("active");
            $(".filter-card-wrp-inner").addClass("inactive")
        });

    })

    //Search on mobile
    $(".closeMobileSearch").on("click", function() {
        $(this).parent().slideUp();
        $(".openSearch").children("i").removeClass("fa-close").addClass("fa-search");
        $('html').removeClass("overflow-hidden");

    })

    //T&C Click
    $(".textblue-click").on("click", function() {
        $(this).addClass("text-blue");
        $(".textorange-click").removeClass("text-orange");
        $(".textgreen-click").removeClass("text-green");
        $(".textpink-click").removeClass("text-pink");

    })

    $(".textorange-click").on("click", function() {
        $(this).addClass("text-orange");
        $(".textblue-click").removeClass("text-blue");
        $(".textgreen-click").removeClass("text-green");
        $(".textpink-click").removeClass("text-pink");
    })

    $(".textgreen-click").on("click", function() {
        $(this).addClass("text-green");
        $(".textblue-click").removeClass("text-blue");
        $(".textorange-click").removeClass("text-orange");
        $(".textpink-click").removeClass("text-pink");
    })

    $(".textpink-click").on("click", function() {
        $(this).addClass("text-pink");
        $(".textblue-click").removeClass("text-blue");
        $(".textorange-click").removeClass("text-orange");
        $(".textgreen-click").removeClass("text-green");
    })

    //mobile Media
    if ($(window).width() <= 768) {

        $(".accordion-true").attr("uk-accordion", "collapsible:true");

        var util = UIkit.util;
        var el4 = util.$('.categories-slider');
        UIkit.scrollspy(el4, {
            repeat: true,
            delay: 100,

        });
        util.on(el4, 'inview', function() {
            $(".filter-floating-btn").css("display", "none")
        });
        util.on(el4, 'outview', function() {
            $(".filter-floating-btn").css("display", "block")
        });

    }

    //File upload preview
    $("#fileupload").change(function(event) {
        var x = URL.createObjectURL(event.target.files[0]);
        $("#upload-img").attr("src", x);
    });

    //05 Oct Filter Dropdown Close
    /*$(".close-x").click(function () {
      //$(this).parents(".filter-dropdown-body").toggleClass("hideme");
      $(".filter-dropdown-body").removeClass("uk-open uk-dropdown-bottom-left");
      $(".addoverlay").removeClass("overlay");

    })*/

    //05 Oct overlay effect
    /*$(".openoverlay").click(function() {
      $(".addoverlay").addClass("overlay");
      $(".filter-dropdown-body").removeClass("uk-open uk-dropdown-bottom-left");
      $(this).parents(".filter-body").find(".filter-dropdown-body").addClass("uk-open uk-dropdown-bottom-left");
    })*/

    //05 Custom Scroll
    $(".customscroll").mCustomScrollbar({
        axis: "y",
    })

});